.no-padding{
    padding: 0;
}
.margin-auto{
    margin: auto;
}
.margin-auto-left{
    margin-left: auto;
}
.margin-auto-x{
    margin-left: auto;
    margin-right: auto;
}
.margin-auto-y{
    margin-top: auto;
    margin-bottom: auto;
}