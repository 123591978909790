.content-header{
    width: 100%;
    height: 20%;
}

.box-container-all{
    background-color:#F6F8FA;
    width: 100%;
    height: 100%;
    margin:0% 0% 0% 0%;
}

.box-container{
    margin: 3% 1% 3% 12%;
    background-color:#F6F8FA;
}