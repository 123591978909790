._card-common {
    background:  var(--sherpa-white);
    border: 1px solid var(--sherpa-carbon-200);
    border-radius: 8px;
};

.line-separator{
    height: 1px;
    border: none;
    background-color: #E3E3E3;
}