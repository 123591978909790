._confirmationReserve-card-container {
  position: absolute;
  @extend ._card-common;
  width: 55%;
  height: 349px;
  padding: 3% 5%;
  left: 22%;
}

._confirmationReserve-replaced-msg {
  margin-left: var(--sherpa-spacing-1);
  color: var(--sherpa-carbon-800);
}

._confirmationReserve-col-info {
  width: 27%;
}
._confirmationReserve-col-info-bigger {
  width: 46%;
}

._confirmationReserve-card-container {
  & #col-0,#col-3 {
    width: 46%;
  }
}

._confirmationReserve-col-info label, ._confirmationReserve-col-info-bigger label {
  color: var(--sherpa-carbon-600);
  font-weight: 600;
  & #field4-replaced-true {
    color: var(--sherpa-warning-500);
  }
}

._confirmationReserve-col-info span, ._confirmationReserve-col-info-bigger span {
  display: block;
  color: var(--sherpa-carbon-900);
  font-weight: 500;
}
._confirmationReserve-card-container #btn-copy {
  width: 35%;
}