._flex-d-flex {
    display: flex;
};

._flex-aling-self-end {
    align-self: flex-end;
};

._flex-justify-end {
    display: flex;
    justify-content: flex-end;
}
.flex-row {
    display: flex;
    flex-direction: row;
};

._flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
};
._flex-j-center {
    display: flex;
    justify-content: center;
};

._flex-j-space {
    display: flex;
    justify-content: space-between;
};

._flex-a-center {
    display: flex;
    align-items: center;
};

._flex-wrap {
    display: flex;
    flex-wrap: wrap;
}