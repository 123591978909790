._textModal-box {
    @extend ._card-common;
    width: 62%;
    margin: auto;
    padding: 33px 42px;
}
._dropDwonModal-box {
    @extend ._card-common;
    width: 43%;
    margin: auto;
    padding: 33px 42px;
}