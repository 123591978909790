.title-label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 200px auto 0px auto;
    width: 300px;
}

.button-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.toast-container{
    min-height: 90px;
}