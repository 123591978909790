._indicatorsLeader-card-container {
    @extend ._card-common;
    @extend ._flex-a-center;
    height: 72px;
}

._indicatorsLeader-box-totals {
    @extend ._flex-d-flex;
    width: 80%;
}

._indicatorsLeader-col {
    width: 50%;
}
._indicatorsLeader-col span {
    display: block;
    font-weight: 600;
    color: var(--sherpa-carbon-900);

}
._indicatorsLeader-col label {
    color: var(--sherpa-carbon-800);
}