.container-titel{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3% 0% 3% 0%;
    
}

.container-subtitel{
    width: 60%;
    height: 20%;
    display: flex;
    align-items: center;
}

.user-sub-titel{
    color: #002C76;
    letter-spacing: -0.0307692px;
}

.user-name{
    color: #002C76;
    font-size: 16px;
    margin: 0% 0% 0% 1%;
}

.daily-management{
    height: 20%;
    margin: 0% 10% 0% 0%;
}


.container-process-card{
    width: 60%;
    display: flex;
    margin: 3% 0% 0% 0%;
    padding: 0% 0% 0% 0%;

}

.process-card{
    width: 31%;
    margin: 0% 3% 0% 0%;
    cursor: pointer;
}

.name-process {
    font-style: normal;
    font-size: 24px;
    letter-spacing: -0.0346154px; 
    color: #333333;
}


.tool-name{
    color: #5C5C5C;
}