._requestReserve-card-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @extend ._card-common;
    width: 50%;
    height: 456px;
    padding: 2% 4%;
};

._requestReserve-col-info {
    width: 50%;
    padding-right: 3px;
};

._requestReserve_tittle {
    color: var(--sherpa-carbon-900);
};
._requestReserve_label {
    color: var(--sherpa-carbon-600);
    font-weight: 600;
};
._requestReserve_text {
    display: block;
    color: var(--sherpa-carbon-900);
    font-weight: 500;
};

._requestReserve-col-info #termObligation {
    width: var(--sherpa-spacing-11);
    margin-right: var(--sherpa-spacing-3)
};
._requestReserve-col-info #fechaCorteFiscal {
    width: 80% !important;
    display: block
};

._requestReserve-col-info #unitTerm {
    margin-top: var(--sherpa-spacing-3);
};

#btn-request {
    position: absolute;
    width: 30%;
    bottom: var(--sherpa-spacing-5);
};
