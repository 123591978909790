.min-w-300 {
    min-width: 300px;
}
.max-w-400 {
    max-width: 400px;
}
.input-prioritize-w{
    width: 394px;
}
._prioritizeLeader-card-container {
    @extend ._card-common;
    @extend ._flex-a-center;
    height: 96px;
}
.vertical-line-separator{
    border-left: 1px solid #E3E3E3;
    height: 48px;
    margin: 0 16px;
}

._prioritizeLeader-card-order-returned {
    @extend ._card-common;
    width: 15%;
    height: 120px;
    padding: 19px 36px 11px 16px;
}
._prioritizeLeader-card-status {
    color: var(--sherpa-error-500)
}