.text-left{
    text-align: left!important;
}
.text-center{
    text-align: center!important;
}
.text-right{
    text-align: right!important;
}
.text-carbon-900{
    font-weight: 600;
    color: var(--sherpa-carbon-900)
}
.text-carbon-800{
    font-weight: 400;
    color: var(--sherpa-carbon-800)
}